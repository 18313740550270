/** Colors **/
/* Maybe create a css-file for base variables instead of having them here? **/
:root {
  --orange: #ff9900;
  --blue: #283f4c;
  --turquoise: #17aec3;
  --dark-grey: #696969;
  --light-grey: #e0e0e0;
  --black: #000;
  --white: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
