.myButton {
  display: inline-block;
  color: white;
}

.mygrid {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50px minmax(110px, 1fr) 200px 200px;
  align-items: center;
}

.headertitle .text {
  color: var(--orange);
  font-size: 18px;
  padding-left: 4px;
}

.headertitle .logo {
  width: 59px;
}


@media (max-width: 600px) {
  .headertitle .text {
    font-size: 15px;
  }
  
  .headertitle .logo {
    width: 50px;
  }

  .mygrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
  }
  .mygrid > div:nth-child(3) {
    display: none;
  }
}

.toolbar {
  background-color: var(--black);
}
