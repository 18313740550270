.wrapperYear{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap: 10px;
    justify-content: center;
    padding-right: 50px;
    padding-left: 50px;
}
.years{
    display: grid;
    grid-template-columns:100px 100px 100px;
    justify-content: center;
}
.yearBox{
    display:grid;
    align-content: center;
    justify-content: center;
}

.yearBoxPrevNext{
    cursor: pointer;
}
.name{
    width: 100%;
    display:grid;
    justify-content: right;
}

.holiday-count{
    margin: 8px 0;
}

.holiday-count .holiday-count__content{
    background-color: var(--blue);
    color: white;
    padding: 8px;
    display: inline;
}

.holiday-count .holiday-count__numberOfDays{
    font-weight: bold;
}