.box{background-color:#444;color:#fff;border-radius:3px;padding:5px;cursor:pointer;text-align: center}
.past{background-color:grey;cursor:default}
.future:hover{background-color:#aaaaaa;
    transition: all 0.1s ease-in;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    
}
.holiday{font-weight:bolder;color:#f99; cursor: default;}
.leave_901{font-weight:bolder;color:#000;background-color:#7fdbff}
.leave_904{font-weight:bolder;color:#e4e4e4;background-color:#0074d9}
.leave_905{font-weight:bolder;color:#000;background-color:#39cccc}
.leave_906{font-weight:bolder;color:#000;background-color:#3d9970}
.leave_907{font-weight:bolder;color:#000;background-color:#2ecc40}
.leave_908{font-weight:bolder;color:#000;background-color:#01ff70}
.leave_909{font-weight:bolder;color:#000;background-color:#ffdc00}
.leave_911{font-weight:bolder;color:#000;background-color:#ff851b}
.leave_912{font-weight:bolder;color:#000;background-color:#ff4136}
.loading{visibility:hidden}