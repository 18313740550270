.days{
    display:grid;
    grid-template-columns: 30px 30px 30px 30px 30px 30px 30px;
    grid-gap: 2px;
    justify-content: center;
}


.title{
    font-size: 20px;
    margin-left: 30px;
}
.testing{
    display:grid;
    grid-template-columns: 1fr;
    justify-content: center;
}
.small{
    display: grid;
    grid-template-columns: minmax(min-content, min-content);
    justify-content: center;
    grid-template-rows: 50px  minmax(min-content, min-content)
}

.weekNumsAndDays{
    display: grid;
    grid-template-columns: 30px 1fr;
}

.weekNum{
    height: 28px;
    vertical-align: middle;
    display: grid;
    justify-content: right;
    padding-right: 3px;
    padding-top: 3px;
    font-size: 10px;
    align-items: center;
}
.weekNum:hover{
    cursor: pointer;
    transform:scale(1.3)
}
.weekNums{
    display: grid;
    grid-template-rows: 29px;
    justify-content: right;
    padding-right: 3px;
}